<template>
  <div class="item">
    <div class="top">
      <div class="l">
        延期天数：<span>{{ item.delayDays }}天</span>
      </div>
      <div class="r">
        申请时间：<span>{{ item.applyTime }}</span>
      </div>
    </div>
    <div class="content">
      <div class="line">
        审核状态：<span
          class="status"
          :class="{ PENDING: 'warning', PASS: 'success', REJECT: 'danger' }[
              item.applyStatus
            ]"
          >{{ item.applyStatus | enumMessage(getDelayApplyStatusEnum) }}</span
        >
      </div>
      <div class="line">审核意见：{{ item.opinion | dash }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    default: () => {},
  },
  data() {
    return {};
  },
  mounted() {
    this.fetchEnums("getDelayApplyStatusEnum");
  },
};
</script>
<style lang="less" scoped>
.success {
  color: #07c160;
}
.danger {
  color: #ee0a24;
}
.warning{
  color: #fa9540;
}
.item {
  background: white;
  margin-bottom: 10px;
  padding-bottom: 10px;
  .top {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
  }
  .content {
    .line {
      padding: 3px 10px;
      line-height: 1.5em;
    }
  }
}
</style>